import { createRootRouteWithContext, Outlet, ScrollRestoration, useLocation } from '@tanstack/react-router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Toaster } from '@/components/ui/sonner.tsx';
import React, { Suspense, useEffect } from 'react';
import { IRouterContext } from '@/router.ts';
import { ErrorComponent } from '@/components/common/error-component.tsx';
import { z } from 'zod';
import { toast } from 'sonner';
import ReactGA from 'react-ga4';

// TODO: Translate all routes into spanish

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

const searchSchema = z.object({
  message: z.string().optional(),
});

export type Search = z.infer<typeof searchSchema>;

export const Route = createRootRouteWithContext<IRouterContext>()({
  validateSearch: searchSchema,
  component: RootComponent,
  notFoundComponent: () => <NotFound />,
  errorComponent: ({ error }) => <ErrorComponent error={error as Error} />,
});

function RootComponent() {
  const { message } = Route.useSearch<Search>();
  const location = useLocation();

  useEffect(() => {
    try {
      ReactGA.send({ hitType: 'pageview', path: location.pathname + location.searchStr });
    } catch (error) {
      console.error('Error tracking pageview', error);
    }
  }, [location]);

  // Show a message after a delay
  useEffect(() => {
    if (message) {
      // Remove the message from the URL
      window.location.replace(window.location.pathname);

      setTimeout(() => {
        toast.success(message);
      }, 1000);
    }
  }, [message]);

  return (
    <>
      <ScrollRestoration />
      <Outlet />
      <Toaster />
      <Suspense fallback={null}>
        <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />
        <ReactQueryDevtools buttonPosition={'bottom-left'} initialIsOpen={false} />
      </Suspense>
    </>
  );
}

// Not found page using tailwind with back to home button
function NotFound() {
  const navigate = Route.useNavigate();

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold">404</h1>
        <p className="text-lg">Página no encontrada</p>
        <button
          className="mt-5 rounded-md bg-primary px-4 py-2 text-white"
          onClick={() => {
            navigate({
              to: '/',
              replace: true,
            });
          }}
        >
          Volver al inicio
        </button>
      </div>
    </div>
  );
}
