import MoviePoster from '@/components/moviePoster';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { Billboard } from '@/lib/cinepolis/movies/contracts';
import { useQuery } from '@tanstack/react-query';
import { MovieTag } from '@/lib/movies/constants.ts';

type Props = {
  endpoint: () => Promise<Billboard[]>;
  queryKey: string[];
};

export default function MoviesBillboard({ endpoint, queryKey }: Props) {
  const {
    data: billboardPoster,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: queryKey,
    queryFn: endpoint,
  });

  if (error && !isLoading) {
    return (
      <>
        <p className="my-5 text-white">Tuvimos un problema al cargar las peliculas!</p>
        <Button variant="outline" onClick={() => refetch()}>
          Cargar Nuevamente
        </Button>
      </>
    );
  }

  return (
    <div className="movies-grid mt-11">
      {isLoading
        ? Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} className="h-full min-h-56 w-full" />)
        : billboardPoster?.map((item) => (
            <MoviePoster
              key={item.id}
              className="mb-10"
              img={item.poster_url}
              title={item.title_translated}
              link={`/peliculas/${item.id}`}
              label={{ text: item.badge_string, className: MovieTag[item.badge_color] }}
            />
          ))}
    </div>
  );
}
