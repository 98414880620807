import { createFileRoute } from '@tanstack/react-router';
import ShowtimeSelector from '@/lib/movies/components/showtime-selector.tsx';
import { useQuery } from '@tanstack/react-query';
import { show } from '@/lib/cinepolis/movies/api.ts';
import LoadingIndicator from '@/components/ui/loadingIndicator.tsx';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx';
import Separator from '@/components/ui/separator.tsx';
import { Helmet } from 'react-helmet-async';
import MoviePoster from '@/components/moviePoster';
import { MovieTag } from '@/lib/movies/constants.ts';
import { ClockIcon, UserIcon } from 'lucide-react';

// TODO: Fetch movie details from API
export const Route = createFileRoute('/_checkAuth/_layout/peliculas/$movieId')({
  component: () => <MovieDetailPage />,
});

function MovieDetailPage() {
  const { movieId } = Route.useParams<{ movieId: string }>();

  const { data: movie, isPending } = useQuery({
    queryKey: ['movie', movieId],
    queryFn: () => show(parseInt(movieId)),
    throwOnError: true,
  });

  return (
    <>
      <Helmet>
        <title>{movie?.title_translated ?? 'Películas'} | MovieClub</title>
      </Helmet>
      {isPending && (
        <div className="flex items-center justify-center p-6">
          <LoadingIndicator />
        </div>
      )}
      {!isPending && !!movie && (
        <>
          {!!movie.title_translated && (
            <h1 className="mb-6 text-xl font-bold text-primary">{movie.title_translated}</h1>
          )}
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            <div className="flex flex-col space-y-6">
              {!!movie.videos && !!movie.videos.length && (
                <>
                  <div className="aspect-video">
                    <iframe
                      className="h-full w-full"
                      src={`https://www.youtube.com/embed/${movie.videos[0].key}?rel=0&amp;controls=0&amp;showinfo=0`}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <Separator orientation="horizontal" />
                </>
              )}

              <div className="flex flex-row items-start lg:space-x-4">
                <div className="hidden lg:flex lg:flex-row lg:items-center lg:justify-center">
                  <div>
                    <MoviePoster
                      className="max-w-[154px]"
                      img={movie.poster_url}
                      label={
                        !!movie.badge_string && !!movie.badge_color
                          ? {
                              text: movie.badge_string,
                              className: MovieTag[movie.badge_color],
                            }
                          : undefined
                      }
                    />
                    <div className="mt-2 flex flex-row justify-around">
                      {!!movie.runtime && movie.runtime > 0 && (
                        <div className="flex flex-col items-center justify-center">
                          <ClockIcon className="h-6 w-6 text-primary" />
                          <div>{movie.runtime} m</div>
                        </div>
                      )}
                      <div className="flex flex-col items-center justify-center">
                        <UserIcon className="h-6 w-6 text-primary" />
                        <div>{movie.parental_guide ?? 'N/A'}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex-1">
                  <Tabs defaultValue="synopsis" className="w-full">
                    <TabsList className="grid w-full grid-cols-2">
                      <TabsTrigger value="synopsis">Sinopsis</TabsTrigger>
                      <TabsTrigger value="technical_data">Datos Técnicos</TabsTrigger>
                    </TabsList>
                    <TabsContent value="synopsis" className="animate-in fade-in">
                      <p className="p-2">{movie.overview}</p>
                    </TabsContent>
                    <TabsContent value="technical_data" className="animate-in fade-in">
                      <p className="p-2">
                        <strong>Título Original</strong>: {movie.title_original}
                        <br />
                        {!!movie.production_countries && (
                          <>
                            <strong>Origen</strong>: {movie.production_countries}
                            <br />
                          </>
                        )}
                        {!!movie.genres && (
                          <>
                            <strong>Género</strong>: {movie.genres.join(', ')}
                            <br />
                          </>
                        )}
                        {!!movie.director && (
                          <>
                            <strong>Director</strong>: {movie.director}
                            <br />
                          </>
                        )}
                        {movie.cast && (
                          <>
                            <strong>Actores</strong>: {movie.cast}
                            <br />
                          </>
                        )}
                        <strong>Calificación</strong>: {movie.parental_guide ?? 'N/A'}
                        <br />
                        {!!movie.runtime && movie.runtime > 0 && (
                          <>
                            <strong>Duración</strong>: {movie.runtime} min.
                            <br />
                          </>
                        )}
                        {movie.distributor && (
                          <>
                            <strong>Distribuidora</strong>: {movie.distributor}
                            <br />
                          </>
                        )}
                      </p>
                    </TabsContent>
                  </Tabs>
                </div>
              </div>
            </div>

            <Separator orientation="horizontal" className="lg:hidden" />

            <div>
              <ShowtimeSelector movie={movie} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
