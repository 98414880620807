import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import Loading from '@/components/common/loading.tsx';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';

export const Route = createFileRoute('/registro')({
  component: () => <Register />,
});

function Register() {
  const { redirect } = Route.useSearch<{ redirect?: string }>();
  const { signup } = useAuthState();

  useEffect(() => {
    console.debug('Redirecting to signup...');

    signup({
      returnTo: redirect,
    });
  }, [redirect, signup]);

  return <Loading />;
}
