import { cn } from '@/lib/utils';
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from '@tanstack/react-router';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropDownMenu';
import { Button } from '@/components/ui/button';
import { useAuthState } from '@/lib/auth/hooks/useAuthState.ts';
import { CircleHelpIcon, UserRound } from 'lucide-react';
import { useMember } from '@/lib/members/hooks/useMember.ts';
import igIconImg from '@/assets/ig-icon.jpg';
import fbIconImg from '@/assets/fb-icon.jpg';

type headerProps = {
  scrollPosShrink?: number;
  className?: string;
};

const Header: FC<headerProps> = ({ scrollPosShrink = 100, className }: headerProps): ReactElement => {
  const initialLogoHeight = 96;
  const minLogoHeight = 70;

  const [size, setSize] = useState(initialLogoHeight);
  const { login, logout, isAuthenticated } = useAuthState();
  const { member } = useMember();
  const navigate = useNavigate();
  const userPic = useRef(member?.picture);

  const menuItems = useMemo(() => {
    return [
      { label: 'Mi Cuenta', path: '/miembros', show: isAuthenticated },
      { label: 'Cerrar Sesión', action: () => logout(), show: isAuthenticated },
      { label: 'Iniciar Sesión', action: () => login(), show: !isAuthenticated },
    ];
  }, [isAuthenticated, isAuthenticated, login, logout]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        const zoomedHeight = initialLogoHeight - window.scrollY * 0.05;

        if (zoomedHeight > minLogoHeight) {
          setSize(zoomedHeight);
        } else {
          setSize(minLogoHeight);
        }
      });

      return () => {
        window.removeEventListener('scroll', () => {});
      };
    }
  }, [scrollPosShrink]);

  return (
    <header className={cn('top-0 bg-white lg:sticky', className)}>
      <div className="sm:site-container relative flex items-center justify-between px-2 md:px-4">
        <div className="hidden lg:block" style={{ height: `${size}px` }}>
          <Link to="/" className="h-full">
            <img className={cn('mt-2 h-full sm:mt-0')} src="/img/logo_header-2.png" alt="logo" />
          </Link>
        </div>
        <div className="h-14 lg:hidden">
          <Link to="/" className="h-full">
            <img className={cn('h-full sm:mt-0')} src="/img/logo_header-2.png" alt="logo" />
          </Link>
        </div>

        <div className="flex items-center space-x-2 md:space-x-4">
          <Link to="/" hash="movies" className="hidden md:block">
            <Button className="p-3 text-xs sm:text-base" radius="sm" variant="outline-primary">
              Sacar entradas
            </Button>
          </Link>

          {!isAuthenticated && (
            <Link to="/asociate-ahora">
              <Button className="p-3 text-xs sm:text-base" radius="sm" variant="outline-default">
                Asociate ahora
              </Button>
            </Link>
          )}

          <a href="https://www.instagram.com/MovieClubAr/" target="_blank" className="hidden md:block">
            <img
              className="flex h-auto w-6 justify-center transition-all duration-300 hover:opacity-60"
              src={igIconImg}
              alt="instagram"
              title="Instagram"
            />
          </a>

          <a href="https://www.facebook.com/MovieClubAr" target="_blank" className="hidden md:block">
            <img
              className="flex h-auto w-6 justify-center transition-all duration-300 hover:opacity-60"
              src={fbIconImg}
              alt="facebook"
              title="Facebook"
            />
          </a>

          <a href="https://soporte.cinepolis.com.ar/support/home" target="_blank" rel="noopener" title="Contacto">
            <CircleHelpIcon className="h-5 w-5" />
          </a>

          <DropdownMenu>
            <DropdownMenuTrigger className="mr-2">
              <div className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-secondary p-0 ring-primary hover:bg-secondary-hover">
                {userPic.current ? (
                  <img src={userPic.current} alt="usuario" />
                ) : (
                  <UserRound className="h-6 w-6 text-white" />
                )}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="mr-5">
              {menuItems.map((item, index) => {
                return (
                  item.show && (
                    <DropdownMenuItem
                      key={index}
                      className="p-0 text-center"
                      onClick={() => (item.path ? navigate({ to: item.path }) : item.action && item.action())}
                      inset
                    >
                      <div className="flex h-8 w-full items-center justify-center">{item.label}</div>
                    </DropdownMenuItem>
                  )
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};

export default Header;
