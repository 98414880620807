import { createFileRoute } from '@tanstack/react-router';
import { cn } from '@/lib/utils.ts';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import React, { useMemo } from 'react';
import { money } from '@/lib/money.ts';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table.tsx';
import { useQuery } from '@tanstack/react-query';
import { getMemberTransactions } from '@/lib/members';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Helmet } from 'react-helmet-async';

export const Route = createFileRoute(
  '/_checkAuth/_layout/_authenticated/_member/_layout/miembros/historial-de-recompensas',
)({
  component: () => <Awards />,
});

function Awards() {
  const { data: transactions, isLoading } = useQuery({
    queryKey: ['transactions'],
    queryFn: getMemberTransactions,
  });

  const hasPoints = useMemo(() => {
    return transactions?.some((t) => t.pointsRedeemed !== undefined || t.pointsEarned !== undefined) ?? false;
  }, [transactions]);

  return (
    <>
      <Helmet>
        <title>Histórico de Recompensas | MovieClub</title>
      </Helmet>
      <h2 className=" text-xl font-bold">Histórico de Recompensas</h2>
      <p className="text-xs text-gray-500 sm:text-sm">Se muestran las recompensas de los últimos 90 días</p>
      <div className="mt-4 w-full rounded-sm border border-muted px-8 pt-6">
        <Table className="sm:w-full" key={`table-${hasPoints ? 'points' : 'no-points'}`}>
          <TableHeader>
            <TableRow>
              <TableHead>Fecha</TableHead>
              <TableHead>Recompensa</TableHead>
              <TableHead>Valor</TableHead>
              <TableHead
                className={cn({
                  hidden: !hasPoints,
                })}
              >
                Puntos Canjeados
              </TableHead>
              <TableHead
                className={cn({
                  hidden: !hasPoints,
                })}
              >
                Puntos Obtenidos
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={hasPoints ? 5 : 3} className="text-xs sm:text-base [&>*]:mb-2">
                  <Skeleton className="h-[20px] w-full rounded-sm bg-muted" />
                  <Skeleton className="h-[20px] w-full rounded-sm bg-muted" />
                  <Skeleton className="h-[20px] w-full rounded-sm bg-muted" />
                </TableCell>
              </TableRow>
            ) : !transactions?.length ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="w-full text-center text-secondary">No tienes recompensas</div>
                </TableCell>
              </TableRow>
            ) : (
              React.Children.toArray(
                transactions?.map((td) => {
                  return (
                    <TableRow>
                      <TableCell className="align-top">
                        {format(new Date(td.transactionDate), 'PPP HH:mm', { locale: es })}
                      </TableCell>
                      <TableCell className={cn('flex flex-col space-y-2 align-top')}>
                        <div>
                          <small>Cód:</small> <span className="rounded-sm bg-muted p-1">{td.bookingId}</span>
                        </div>
                        {td.items?.length && (
                          <ul className="flex flex-col space-y-2">
                            {React.Children.toArray(
                              td.items?.map((item) => {
                                return (
                                  <li>
                                    <div>
                                      <div>{item.movieName ?? item.itemName ?? 'Producto desconocido'}</div>
                                      {item.sessionDate && (
                                        <small>{format(new Date(item.sessionDate), 'PPP HH:mm', { locale: es })}</small>
                                      )}
                                    </div>
                                  </li>
                                );
                              }),
                            )}
                          </ul>
                        )}
                        <small>{td.cinemaName}</small>
                      </TableCell>
                      <TableCell className="align-top">{money.format(td.totalValue ?? 0)}</TableCell>
                      <TableCell
                        className={cn('align-top', {
                          hidden: !hasPoints,
                        })}
                      >
                        {td.pointsRedeemed ?? 0}
                      </TableCell>
                      <TableCell
                        className={cn('align-top', {
                          hidden: !hasPoints,
                        })}
                      >
                        {td.pointsEarned ?? 0}
                      </TableCell>
                    </TableRow>
                  );
                }),
              )
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
